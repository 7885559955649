/* istanbul ignore file */

import * as Sentry from '@sentry/angular-ivy';

import { ErrorHandler, Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { WindowService } from '@yoimo/joymo-ui';
import { environment } from '@environment';

/** Thrown when the current lazy loaded routes become stale after a deploy */
const FAILED_CHUNK_ERR = /Loading chunk [\d]+ failed/g;

let useSentry = false;
type LogCategory = 'AUTH' | 'CONTENT-ACCESS' | 'PAYMENTS';
/**
 * Handle error logs/traces.
 * If running on local environment it prints to console, otherwise uses Sentry.
 */
@Injectable({ providedIn: 'root' })
export class LoggingService implements ErrorHandler {
  constructor(
    private windowService: WindowService,
    private authService: AuthService
  ) {
    useSentry = this.windowService.document.location.hostname !== 'localhost';
    Sentry.init({
      dsn: environment.sentry?.browser,
      enabled: useSentry,
      environment: environment.firebase.projectId,
      integrations: [new Sentry.BrowserTracing()],
      release: 'f285b81b40ab0b836eed4701ed2b1956d04557a8', // Release name must be replaced manually before building the app
      replaysOnErrorSampleRate: environment.production ? 0.1 : 0,
      replaysSessionSampleRate: 0,
      tracesSampleRate: environment.production ? 0.25 : 1.0,
    });
    this.linkToUser();
  }

  /**
   * Creates an exception event
   *
   * @param error any error or exception
   * @param context Sentry capture context, read Sentry documentation for more information
   * @returns event id for the Sentry event created
   */
  static logError(error: Error | string, context?: Record<string, any>): void {
    if (!environment.production) console.error(error);
    if (!useSentry) return;
    Sentry.captureException(error, context);
  }

  static logCriticalError(error: Error | string, category: LogCategory): void {
    this.logError(error, {
      level: 'fatal',
      tags: { category },
    });
  }

  static setBreadcrumb(
    message: string,
    origin?: LogCategory,
    additionalData?: Record<string, any>
  ): void {
    if (!useSentry) {
      // eslint-disable-next-line no-restricted-globals
      console.warn(message);
      return;
    }
    Sentry.addBreadcrumb({ message, data: { origin, additionalData } });
  }

  /**
   * @deprecated Meant to be a global listener for uncaptured exceptions
   * @see LoggingService.logError If you need to manually capture something
   */
  handleError(error: any): void {
    if (FAILED_CHUNK_ERR.test(error)) {
      this.askToReload();
      return;
    }
    LoggingService.logError(error);
  }

  private askToReload(): void {
    if (
      this.windowService.window?.confirm(
        $localize`:@@newVersionAvailable:A new version is available! Do you want to update?`
      )
    ) {
      this.windowService.document.location.reload();
    }
  }

  private linkToUser(): void {
    this.authService.auth.onAuthStateChanged((user) => {
      if (!user?.email) {
        Sentry.setUser(null);
        return;
      }
      Sentry.setUser({ email: user.email });
    });
  }
}

export const logger = LoggingService;
